


































































import { Component } from 'vue-property-decorator';
import RoomLayoutsToolbar from './RoomLayoutsToolbar.vue';

@Component
export default class RoomLayoutsBottomToolbar extends RoomLayoutsToolbar {
}
